<template>
    <div class="lexicon-update">
        <!-- 编辑词库顶部 -->
        <div class="lexicon-update-top">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="callback"></i>
            <div class="lexicon-update-top-text">{{receivedData}}</div>
        </div>

        <!-- 词库违规词类型 -->
        <div class="lexicon-violation-words">
            <div class="violation-words">
                <div :class="{'violation-words-name':true,'click-style':wordsOne}" @click="wordsOneClick">违规词</div>
            </div>
            <div class="violation-words">
                <div :class="{'violation-words-name':true,'click-style':wordsTwo}" @click="wordsTwoClick">白名单</div>
            </div>
            <div class="violation-words">
                <div :class="{'violation-words-name':true,'click-style':wordsThree}" @click="wordsThreeClick">关键词</div>
            </div>
        </div>

        <!-- 搜查添加 -->
        <div class="lexicon-content">
            <div class="content-select">
                <span class="content-select-name">名称</span>
                <el-input v-model="selectName"></el-input>
            </div>

            <div class="analyse-time">
                <span class="analyse-time-text">分析时间</span>
                <el-date-picker
                    v-model="dataRange"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </div>

            <div>
                <el-button class="select-button">查找</el-button>
            </div>

            <div>
                <el-button class="add-keyword">添加关键词</el-button>
            </div>

            <div>
                <el-button class="export">导出</el-button>
            </div>
        </div>

        <!-- 词库具体内容 -->
        <div class="lexicon-content-list">
            <div class="content-list-type">
                <div style="width: 20.3%;" class="type-keyword">关键词</div>
                <div style="width: 13.1%;">分类</div>
                <div style="width: 28.4%;">备注</div>
                <div style="width: 11%;">状态</div>
                <div style="width: 13%;">添加/更新时间</div>
                <div >操作</div>
            </div>
            
            <div v-for="(item,index) in lexiconList" :key="item.id" class="content-list-item">
                <div style="width: 19.5%;" class="type-keyword-item">{{ item.keyword }}</div>
                <div style="width: 14%;">{{ item.type }}</div>
                <div style="width: 28%;">{{ item.remark }}1</div>
                <div style="width: 11%;">{{ item.status }}</div>
                <div style="width: 13%;">{{ item.time }}</div>
                <div style="width: 13%;" class="operate-button" v-if="index == 0" >
                    <el-button class="update-item">编辑</el-button>
                    <el-button class="update-status">修改状态</el-button>
                    <div></div>
                    <el-button class="delete-item">删除</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                wordsOne:true,
                wordsTwo:false,
                wordsThree:false,

                selectName:"",
                dataRange: [],

                // 词库具体内容数组
                lexiconList:[
                    {id:1, keyword:"最大",type:"敏感词",remark:"",status:"正常",time:"2024-5-9 10:00"},
                    {id:2, keyword:"最大",type:"敏感词",remark:"",status:"正常",time:"2024-5-9 10:00"}
                ],

                // 控制按钮展示次数
                rendered:true,
            }
        },
        props:['item'],
        computed:{
            receivedData(){
                return this.item;
            }
        },

        methods:{
            wordsOneClick(){
                this.wordsTwo = false;
                this.wordsThree = false;
                this.wordsOne = true;
            },
            wordsTwoClick(){
                this.wordsOne = false;
                this.wordsThree = false;
                this.wordsTwo = true;
            },
            wordsThreeClick(){
                this.wordsOne = false;
                this.wordsTwo = false;
                this.wordsThree = true;
            },
            callback(){
                this.$emit('child-event','lexiconAdd');
            },
            updateButton(){
                this.rendered = false
            }
        }
    }
</script>

<style scoped>

    /* 编辑词库顶部 */
    .lexicon-update-top{
        display: flex;
        align-items: center;
        font-size: 20px;
        border-bottom: 1px solid #DCE0E7;
        padding-bottom: 13px;
        margin-left: 14px;
        margin-right: 14px;
    }
    .lexicon-update-top-text{
        font-size: 16px;
        color: #2E3742;
        margin-left: 8px;
    }

    /* #region 编辑词库搜查添加 */
    .lexicon-violation-words{
        display: flex;
        align-items: center;
        margin: 16px 0px 16px 15px;
    }

    .violation-words{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 88px;
        height: 32px;
        border-radius: 8px;
        background-color: #E6F2FF;
        margin-right: 12px;
    }
    .violation-words:hover{
        cursor: pointer;
    }
    .violation-words-name{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0077FF;
        font-size: 14px;
        width: 88px;
    }

    .lexicon-content{
        display: flex;
        margin-left: 14px;
    }

    .content-select{
        display: flex;
        align-items: center;
        width: 236px;
        height: 32px;
        margin-right: 24px;
    }
    .content-select-name{
        font-size: 13px;
        color: #2E3742;
        white-space: nowrap;
        margin-right: 10px;
    }

    .analyse-time{
        display: flex;
        align-items: center;
        width: 340px;
        height: 32px;
        white-space: nowrap;
    }

    .analyse-time-text{
        font-size: 13px;
        color: #2E3742;
        white-space: nowrap; /* 防止文字换行 */
        margin-right: 8px;
    }

    .select-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 66px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #0077FF;
        margin-left: 11px;
        color: #0077FF;
        font-size: 13px;
    }
    
    .add-keyword{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 94px;
        height: 32px;
        background-color: #0077FF;
        border-radius: 4px;
        margin-left: 61px;
        font-size: 13px;
        color: #FFFFFF;
    }

    .export{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 32px;
        border-radius: 4px;
        margin-left: 16px;
        color: #2E3742 ;
        font-size: 13px;
    }

    /* #endregion */

    /* 词库具体内容 */
    .lexicon-content-list{
        height: 318px;
        margin:20px 14px 0px 14px;
    }
    .content-list-type{
        display: flex;
        align-items: center;
        height: 28px;
        border-radius: 4px;
        background-color: #F5F7F9;
        font-size: 12px;
        color: #2E3742;
    }
    .type-keyword{
        
        margin-left: 16px;
    }

    .content-list-item{
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #2E3742;
        height: 35px;
        border-bottom: 1px solid #DCE0E7;
        margin-top: 14px;
    }
    .type-keyword-item{
        
        margin-left: 20px;
    }

    .operate-button{
        display: flex;
        align-items: center;
        width: 14.5%;
    }
    .update-item{
        font-size: 13px;
        color: #2E3742;
        width: 37px;
        height: 24px;
        border: 1px solid #B4BCCA;
        border-radius: 4px;
        margin-right: 14px;
    }

    .update-status{
        font-size: 13px;
        color: #2E3742;
        width: 64px;
        height: 24px;
        border: 1px solid #B4BCCA;
        border-radius: 4px;
    }

    .delete-item{
        font-size: 13px;
        color: #2E3742;
        width: 31px;
        height: 18px;
        margin-left: 19px;
    }


    /* 点击后改变样式 */
    .click-style{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 86px;
        height: 32px;
        background-color: #0077FF;
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 14px;
    }


    /* 改变element-ui默认样式 */
    ::v-deep .el-input__inner{
        display: flex;
        align-items: center; 
        justify-content: center;
        width: 200px;
        height: 32px;
    }

    ::v-deep .el-date-editor.el-input__inner {
        padding: 0px;
        width: 280px;
    }

    /* ::v-deep .el-input__icon {
        display: none;
    } */


    ::v-deep .el-button{
        padding: 0;
    }
    
</style>